<template>
  <v-container fluid>
    <v-row class="pt-4">
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Invoice details #{{ invoice.invoiceNumber }}
          &nbsp;|&nbsp;Invoice status: <span class="highlight">{{ invoice.statusWord }}</span>
        </h1>
        <p v-if="invoice.vatStatus === 1">
          This invoice is issued in compliance with HMRC Domestic Reverse Charging Scheme for the Construction Industry
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <p>
          {{ invoice.customer_name }}<br />
          <span v-if="invoice.addressOne">{{ invoice.addressOne }}</span>
          <span v-if="invoice.addressTwo">{{ invoice.addressTwo }}</span>
          <span v-if="invoice.addressThree">{{ invoice.addressThree }}</span>
          <span v-if="invoice.addressFour">{{ invoice.addressFour }}</span>
          <span v-if="invoice.addressFive">{{ invoice.addressFive }}</span>
          <span v-if="invoice.postcode">{{ invoice.postcode }}</span>
        </p>
        <p v-html="invoice.additonal_codes"></p>
      </v-col>
      <v-col class="col-6">
        <p>
          Document number: {{ invoice.jobNumber }}<br />
          PO number: {{ invoice.poNumber}}<br />
          Date: {{ invoice.date | tinyDate }}<br />
          Account reference: {{ invoice.accountReference }}
        </p>
        <p v-html="invoice.notes"></p>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Material</th>
                <th class="text-left">Colour</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Units</th>
                <th class="text-left">Rate</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in invoice.lines" :key="index">
                <td>{{ line.description }}</td>
                <td>{{ line.material }}</td>
                <td>{{ line.colour }}</td>
                <td>{{ line.quantity }}</td>
                <td>{{ line.units }}</td>
                <td>{{ line.pounds || currency }}</td>
                <td>{{ (line.quantity * line.pounds) | currency }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <p v-html="invoice.description_notes"></p>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <p>{{ invoice.siteLocation }}</p>
        <p>{{ invoice.workDescription }}</p>
        <p>{{ invoice.contact }}</p>
      </v-col>
      <v-col class="col-6 pr-20">
        <p>
          Sub-total:
          <span class="float-right">
            {{ subTotal | priceInPounds | currency }}
          </span>
        </p>
        <p>
          VAT @20%:
          <span v-if="invoice.status !== 1" class="float-right">
            <span v-if="invoice.vatStatus === 2">
              {{ vat | priceInPounds | currency }}
            </span>
          </span>
        </p>
        <p>
          Total:
          <span class="float-right">
            {{ (subTotal + vat) | priceInPounds | currency }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import axios from '../../axios';

export default {
  name: 'InvoicesPaymentHistoryDetails',
  props: [
    'invoiceId',
  ],
  data() {
    return {
      invoice: {
        lines: [],
        date: '',
      },
      subTotal: 0,
      vat: 0,
      message: '',
      showMessage: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  methods: {
    getInvoice() {
      axios.get(`/invoices/getSingle/${this.invoiceId}.json?token=${this.token}`)
      .then((response) => {
        this.invoice = response.data;
        this.subTotal = response.data.value;
        this.vat = response.data.vat;
      });
    },
  },
  mounted() {
    this.getInvoice();
  },
};
</script>