<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-row>
          <v-col>
            <h1>Record payment / retention on #{{ invoice.number  }}</h1>
            <h4>
              Invoice amount: {{ invoice.amount | priceInPounds | currency }}
              Net: {{ invoice.net | priceInPounds | currency }}
              Vat: {{ invoice.vat | priceInPounds | currency }}
              Paid to date: {{ invoice.amountPaid | priceInPounds | currency }}<br />
              Amount allocated from credit note(s): {{ invoice.amountCredited | priceInPounds | currency }}
              Amount due: {{ invoice.amountDue | priceInPounds | currency }}
            </h4>
          </v-col>
        </v-row>
        <v-row v-if="creditNotes.length > 0">
          <v-col>
            {{ creditNotes.length }} credit note<span v-if="creditNotes.length > 1">s</span> <span v-if="creditNotes.length === 1">is</span>
            <span v-else>are</span> on file for this customer:
            <div v-for="(note, index) in creditNotes" :key="`cn_${index}`">
              <p>
                Credit note against Invoice:
                {{ note.invoice.quotation.clone_id }}-66-{{ note.invoice.quotation.quoted_by_initials }}<br />
                Credit note total: {{ note.gross | priceInPounds | currency }}<br />
                Credit note net: {{ note.value | priceInPounds | currency }}<br />
                Credit note VAT: {{ note.vat | priceInPounds | currency }}<br />
                Already used: {{ note.allocation | priceInPounds | currency }}<br />
                Available: {{ note.available | priceInPounds | currency }}
              </p>
              <v-row>
                <v-col class="col-12 col-md-6">
                  <v-text-field v-model="creditNoteToUse" label="Amount of credit to allocate"></v-text-field>    
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-text-field v-model="paymentAmountNet" label="Net amount received"></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-6">
            <v-text-field v-model="paymentAmountVat" label="Vat amount received"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-text-field v-model="discount" label="Discount"></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-6">
            <v-menu
              v-model="paymentDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="paymentDate"
                  label="Date received"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  suffix="*"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="paymentDateProto"
                @input="paymentDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-text-field clearable v-model="retentionAmount" label="Retention amount"></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-6">
            <v-menu
              v-model="retentionDueDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  v-model="retentionDueDate"
                  label="Retention due date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  suffix="*"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="retentionDueDateProto"
                @input="retentionDueDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-text-field v-model="paymentNoteContact" label="Contact"></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-6">
            <v-text-field v-model="paymentNoteTel" label="Contact Tel"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-textarea rows="3" v-model="paymentNote" label="Payment note"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-btn @click="save" large>Save</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-12 col-md-6">
        <History :history="invoice" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="overPaymentAlert"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          You have entered a payment greater than the value of the invoice.
          This has been automatically adjusted down to the total amount outstanding.
          Please check.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="dismissOverpaymentAlert"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import History from '@/components/InvoicePayment/History.vue';
import axios from '../../axios';

export default {
  name: 'Payment',
  props: [
    'invoiceId',
  ],
  components: {
    History,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      creditNotes: [],
      creditNoteToUse: 0,
      discount: 0,
      invoice: {
        payments: [],
        retentions: [],
        notes: [],
      },
      overPaymentAlert: false,
      paymentAmountNet: 0,
      paymentAmountVat: 0,
      paymentDate: null,
      paymentDateMenu: false,
      paymentDateProto: null,
      paymentNote: null,
      paymentNoteContact: null,
      paymentNoteTel: null,
      retentionAmount: 0,
      retentionDueDateMenu: false,
      retentionDueDateProto: null,
      retentionDueDate: null,
    };
  },
  watch: {
    paymentDateProto(value) {
      this.paymentDate = this.formatDate(value);
      if (this.retentionAmount > 0) {
        const dateArray = this.paymentDateProto.split('-');
        let year = dateArray[0];
        year = parseInt(year);
        year += 1;
        const newDate = `${year}-${dateArray[1]}-${dateArray[2]}`;
        this.retentionDueDate = this.formatDate(newDate);
      }
    },
    retentionDueDateProto(value) {
      this.retentionDueDate = this.formatDate(value);
    },
    paymentAmountNet() {
      const amountDue = (this.invoice.amountDue / 100);
      const entered = (parseInt(this.paymentAmountVat) + parseInt(this.paymentAmountNet));
      if (entered > amountDue) {
        this.overPaymentAlert = true;
      }
    },
    paymentAmountVat() {
      const amountDue = (this.invoice.amountDue / 100);
      const entered = (parseInt(this.paymentAmountVat) + parseInt(this.paymentAmountNet));
      if (entered > amountDue) {
        this.overPaymentAlert = true;
      }
    },
  },
  methods: {
    dismissOverpaymentAlert() {
      this.overPaymentAlert = false;
      this.paymentAmount = this.roundTo((this.invoice.amountDue / 100), 2);
    },
    roundTo(n, digits) {
      let negative = false;
      if (digits === undefined) {
          digits = 0;
      }
      if (n < 0) {
          negative = true;
          n = n * -1;
      }
      const multiplicator = Math.pow(10, digits);
      n = parseFloat((n * multiplicator).toFixed(11));
      n = (Math.round(n) / multiplicator).toFixed(digits);
      if (negative) {
          n = (n * -1).toFixed(digits);
      }
      return n;
    },
    save() {
      const postData = {};
      postData.paymentAmountNet = this.paymentAmountNet;
      postData.paymentAmountVat = this.paymentAmountVat;
      postData.discount = this.discount;
      postData.paymentDate = this.paymentDate;
      postData.retentionAmount = this.retentionAmount;
      postData.retentionDueDate = this.retentionDueDate;
      postData.paymentNote = this.paymentNote;
      postData.paymentNoteTel = this.paymentNoteTel;
      postData.paymentNoteContact = this.paymentNoteContact;
      postData.creditNotes = this.creditNotes;
      postData.creditToUse = this.creditNoteToUse;
      postData.userId = this.userid;
      axios.post(`/invoicePayments/savePayment/${this.invoiceId}.json?token=${this.token}`, postData)
        .then((response) => {
          if (response.data.result === true) {
            this.$emit('setMessage', 'The payment record has been updated.');
          } else {
            this.$emit('setMessage', 'The payment record could not be updated. Please try again.');
          }
          this.clearData();
          this.getInvoice();
        });
    },
    clearData() {
      this.invoice = {
        payments: [],
        retentions: [],
        notes: [],
      };
      this.paymentAmount = 0;
      this.paymentDateMenu = false;
      this.paymentDateProto = null;
      this.paymentDate = null;
      this.retentionAmount = 0;
      this.retentionDueDateMenu = false;
      this.retentionDueDateProto = null;
      this.retentionDueDate = null;
      this.paymentNote = null;
      this.paymentNoteTel = null;
      this.paymentNoteContact = null;
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getInvoice() {
      axios.get(`/invoices/getPaymentHistory/${this.invoiceId}.json?token=${this.token}`)
        .then((response) => {
          this.invoice = response.data.history;
          this.creditNotes = response.data.creditNotes;
        });
    },
  },
  mounted() {
    this.getInvoice();
  },
};
</script>