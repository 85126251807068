<template>
  <div>
    <v-row>
      <v-col>
        <h1>Payment history for #{{ history.number  }}</h1>
      </v-col>
    </v-row>
    <v-row v-if="history.payments.length === 0">
      <v-col>
        <h4>No payments have been made on this invoice</h4>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <h4>Payments received</h4>
        <ul>
          <li v-for="(payment, index) in history.payments" :key="`p_${index}`">
            Amount: {{ payment.payment_amount | priceInPounds | currency }}
            Date: {{ payment.payment_date | tinyDate }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="history.retentions.length === 0">
      <v-col>
        <h4>No retentions have been recorded on this invoice</h4>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <h4>Retentions recorded</h4>
        <ul>
          <li v-for="(retention, index) in history.retentions" :key="`r_${index}`">
            Amount: {{ retention.retention_amount | priceInPounds | currency }}
            Paid: {{  retention.payment_made | priceInPounds | currency }}
            Due date: {{ retention.payment_due_date | tinyDate }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="history.notes.length === 0">
      <v-col>
        <h4>No payment notes have been recorded on this invoice</h4>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <h4>Notes</h4>
        <ul>
          <li v-for="(note, index) in history.notes" :key="`n_${index}`">
            Contact: {{ note.contact }}
            Tel: {{  note.tel }}
            Note: {{ note.notes }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'History',
  props: [
    'history',
  ],
};
</script>
