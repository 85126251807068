<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/credit-note/${invoiceId}`">
          <v-btn
            small
            color="red"
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Raise Credit Note
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-tabs v-model="tab">
          <v-tab>Add payment</v-tab>
          <v-tab>Invoice Details</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item><Payment @setMessage="setMessage" :invoiceId="invoiceId" /></v-tab-item>
          <v-tab-item><Details :invoiceId="invoiceId" /></v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <router-link to="/invoices/search">
            <v-btn
              color="orange darken-2"
              text
            >
              OK
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Details from '@/components/InvoicePayment/Details.vue';
import Payment from '@/components/InvoicePayment/Payment.vue';

export default {
  name: 'InvoicesPaymentHistory',
  components: {
    Details,
    Payment,
  },
  computed: {
    invoiceId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      tab: null,
      message: '',
      showMessage: false,
    };
  },
  methods: {
    setMessage(value) {
      this.message = value;
      this.showMessage = true;
    },
  },
};
</script>